//!* Contents
	header {
		z-index: 99 !important;

		nav {
			position: fixed;
			top: 0;
			left: 0;
			background: white;
			box-shadow: $shadow-3;
			width: 275px;
			height: 100%;
			overflow: auto;
			-webkit-overflow-scrolling: touch;
			z-index: 99;
			@include transition(all 300ms ease);

			.panel + .panel { margin-top: 5px !important; }

			.divisor.panel {
				margin: 13px 0 !important; 
				height: 1px;
				background: $color-border-alt !important;

				&:first-child { margin-top: 0 !important; }
			}

			li:not(.divisor) {
				margin-right: $spacing * 0.75 !important;

				li:not(.divisor) {
					margin-right: 0 !important;
				}
			}

			.link-default {
				border-radius: 0 50px 50px 0;
				padding-right: $spacing / 3 !important;
			}

			.link-default:not(.collapsed), .link-default.active {
				background-color: rgba($redrum-3, .12);
			}

			.sublink-default.sublink-active {
				background: none !important;
				color: $redrum-3 !important;
			}

			.brand-image {
				min-height: 111px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}

		.navbar-fixed-top {
			background: $redrum-3;
			color: white;
			box-shadow: $shadow-3;
			border: none;
			padding: 18px ($spacing) 18px 75px;
			z-index: 97;

			.title {
				display: block;
			    font-weight: 500;
			    font-size: 21px;
			    line-height: 24px;
			    overflow: hidden;
			    text-overflow: ellipsis;
			    white-space: nowrap;
			}

			button {
				position: absolute;
				top: 0;
				left: 0;
				width: 75px;
				height: 60px;
				outline: none !important;
				background: none !important;
				border: none;
				color: white;

			    .material-icons { font-size: 32px !important; }
			}
		}

		.navbar-dismiss {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: $redrum-3;
			opacity: 0.5;
			z-index: 98;
			padding: 0;
			border: none;
			opacity: 0;
			visibility: hidden;
			@include transition(all 300ms ease);
		}

		@media (max-width: 991px) {
			nav { @include transform(translateX(-275px)); }

			&.show {
				nav { @include transform(translateX(0px)); }

				.navbar-dismiss {
					opacity: 0.75;
					visibility: visible;
				}
			}
		}

	}	

	footer {
		> div {
			padding: $spacing;
		}

		a {
			display: block;
			margin: 0 auto;
			width: 125px;
			opacity: 0.32;
			transition: all 300ms ease;

			&:hover { opacity: 1; }
		}
	}

	#login + footer {
		padding: ($spacing / 2) $spacing $spacing !important;
	}
	
	.right-container {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		min-height: 100vh;
		padding-left: 275px;
		@include transition(padding 300ms ease);

		@media (max-width : 991px) {
			padding: 60px 0 0;
		}
		
		.content, .heading, footer {
			width: 100%;
		}

		footer {
			display: flex;
			align-items: flex-end;
			justify-content: center;

			@media (min-width : 992px) {
				flex-grow: 1;
			}

			> div {
				width: 100%;
				border-top: 1px solid $color-border;
			}
		}

		.content, .heading {
			padding: ($spacing * 2.5) ($spacing * 2) ($spacing * 2);

			@media (max-width : 767px) {
				padding: ($spacing * 2.5) 7.2vw;
			}

			@media (max-width : 575px) {
				padding: ($spacing * 2) 7.2vw;
			}
		}

		.list {
			padding-left: 0;
			padding-right: 0;

			> .alert, .list-subitem {
				
				margin: ($spacing / 2) ($spacing * 2) $spacing;
				
				@media (max-width : 767px) {
					margin: 0 $spacing $spacing;
				}

				&:last-child { margin-bottom: 0; }
			}
		}

		.heading {
			position: relative;
			padding: 30px ($spacing * 2) 31px;
			z-index: 19;
			background:$redrum-3;
			color: white !important;
			
			@supports (position: sticky) {
				position: sticky;
				top: 0;
				
				@media (max-width : 991px) {
					top: 60px;
				}
			}


			@media (max-width : 767px) {
				padding: ($spacing * 1.5) 7.2vw;
			}

			h1 {
				color: white;
				font-weight: 300;
				margin: 0;

				small {
					display: block;
					margin-bottom: $spacing / 3;
					color: inherit;
					opacity: .6;
					font-weight: 300;

					&.bottom { margin: ($spacing / 3) 0 0; }

					@media (max-width : 767px) { margin: 0 !important; }
				}
			}

			.btns  {
				position: absolute;
				bottom: -30px;
				right: $spacing * 2;

				.btn + .btn { margin-left: 6px; }

				@media (max-width : 767px) {
					bottom: -22px;
					right: 7.2vw;

					.btn-icon { padding: 10px !important; }
				}
			}
		}

		.btn-actions {
			text-align: center;
			margin-top: $spacing * 2;
		}
	}