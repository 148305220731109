//!* Login
	#login {
		position:relative;
		padding: ($spacing * 2) $spacing;
		width: 100%;

		&:before {
			content: '';
			position: absolute;
			bottom: 50%;
			left: -50%;
			border-radius: 100%;
			width: 200%;
			height: 1080px;
			z-index:1;
			transform: rotate(-12deg);
			background:$redrum-3;
			color: white !important;

			@media (max-width : 575px) {
				width: 300%;
				left: -120%;
			}
		}

		.login-form {
			background: white;
			box-shadow: $shadow-3;
			border-radius: $radius * 2;
			padding: ($spacing * 3) ($spacing * 2);
			width: 320px;
			margin: 0 auto;
			position: relative;
			z-index: 2;
			@media (max-width : 359px) {
				width: 280px;
				padding: $spacing + ($spacing / 2);
			}
		}

		.btn {
			margin-top: $spacing;
		}

		.form-group {
			margin: 0;

			& + .form-group {
				margin-top: 1rem;
			}
		}

		.brand-image {
			padding: 0 0 ($spacing * 2);

			img {
				max-height: 80px;
			}
		}
	}