//!* Logs
	#table-log {
		th { outline: none !important; }
		td {
			vertical-align: top !important;
			&.text.inf {
				vertical-align: middle !important;
				line-height: 1.15em !important;
			}
		}
		.stack {
			font-size: .75em;
			padding-top: $spacing;
		}
	}
	#table-log_wrapper {
		> .row {
			margin: 0;
			> div { padding: 0; }
			&:first-child,
			&:last-child {
				margin: 0 ($spacing * 2) $spacing;
				@media (max-width : 767px) {
					margin: 0 $spacing $spacing;
				}
			}
		}
	}
	#table-log_paginate {
		margin-top: -10px;
		text-align: center;
		@media (min-width : 768px) {
			text-align: right;
		}
	}
	#table-log_info {
		margin-top: -10px;
		padding: 10px 0;
		line-height: 1.15em;
		@media (max-width : 767px) {
			text-align: center;
			margin-bottom: $spacing;
		}	
	}
	#table-log_length {
		select {
			display: inline !important;
			width: auto !important;
		}
	}
	.pagination {
		margin: 0;
		a {
			background: none !important;
			border: none !important;
			color: $color-body !important;
		}
		.disabled { opacity: .5; }
		.active { font-weight: bold; }
	}