//!* Blocks
	.blocks-container {
		position: relative;
		max-width: 970px;
		margin: 0 auto;
		padding: $spacing 15px 0;

		@media (max-width : 767px) { padding: $spacing 0 0; }
	}

	#blocks-container {
		.block {
			position: relative;
			padding: ($spacing * 2);
			margin-bottom: ($spacing * 2);
			margin-right: ($spacing + 44px);
			background: white;
			min-height: 150px;
			border-radius: $radius;
			box-shadow: $shadow-1;
			@media (max-width : 767px) {
				padding: $spacing;
				margin: 0 0 (($spacing * 2) + 44px);
			}
			&:first-child .btn-handle-up,
			&:last-child .btn-handle-down {
				opacity: 1 !important;
				box-shadow: none !important; 
				background: #e9e9e9 !important;
				color: #909090 !important;
				@include transform(scale(1));
				cursor: not-allowed !important;
				.tooltip-helper { display: none !important; }
			}
			.actions {
				position: absolute;
				top: 50%;
				left: 100%;
				margin-top: -78px;
				padding-left: $spacing;
				.btn-default-3 { margin-top: 5px; }
				.dropdown { margin-bottom: 15px; }
				@media (max-width : 767px) {
					top: 100%;
					left: 0;
					width: 100%;
					margin: 0;
					padding: ($spacing / 2) 0 0;
					border-top: 1px solid $color-border;
					.btn-default-3 { margin: 0 5px 0 0; }
					.dropdown {
						margin: 0;
						float: right;
					}
				}
			}
			.form-group {
				.label { background: white !important; }
				.form-control {
					font-size: $fsize + 2;
					@media (max-width : 767px) { font-size: $fsize; }
					&.input-title {
						font-size: $fsize + 5;
						@media (max-width : 767px) { font-size: $fsize + 2; }
					}
				}
			}
			.form-image-preview {
				position: relative;

				&.fip_1by1 label { padding-bottom: 100%; }
				&.fip_2by1 label { padding-bottom: 50%; }
				&.fip_4by3 label { padding-bottom: 75%; }
				&.fip_16by9 label { padding-bottom: 56.30%; }

				&:before { height: auto; }
				.label {
					display: block;
					position: relative;
					margin: 0;
					padding: 0;
					font-size: $fsize + 2px;
					cursor: pointer;
					z-index: 1;
					&:before {
						content: '';
						display: block;
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						z-index: 1;
						border: 2px dashed $color-border;
					}
					span {
						display: block;
						position: absolute;
						top: 50%;
						text-align: center;
						left: 10%;
						width: 80%;
						z-index: 2;
						-webkit-transform: translateY(-50%);
						-moz-transform: translateY(-50%);
						-ms-transform: translateY(-50%);
						transform: translateY(-50%);
						.material-icons {
							display: block;
							font-size: 32px;
							margin-bottom: .5em;
						}
					}
				}

				img, iframe {
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					z-index: 3;
				} 
			}
			.form-file:before { height: auto; }
			textarea.form-control {
				max-height: 400px !important;
				min-height: 200px !important;
			}
		}
		.block-content {
			.row {
				font-size: 0;
				> div {
					float: none;
					display: inline-block !important;
					vertical-align: middle;
					font-size: $fsize;
					@media (max-width : 767px) { width: 100%; }
				}
			}

			&.block-title { padding: 12px 0; }	
			&.block-text-image, &.block-two-images {
				textarea.form-control { height: 254px; }
				@media (max-width : 767px) {
					.row > div + div { margin-top: $spacing; }
				}
			}
		}
	}

	.blocks-selector {
		position: relative;
		margin: ($spacing * 2) 0 ($spacing * 3);
		text-align: center;
		padding-top: 13px;
		@media (max-width : 767px) { margin: $spacing 0; }
		.blocks-selector-isset {
			display: inline-block;
			padding: ($spacing / 2) $spacing ($spacing / 2) ($spacing / 2);
			border: 2px solid $color-border;
			border-radius: 100px;
			> h3 { margin: 0 0 0 ($spacing - 5); }
			> .inline { vertical-align: middle; }
			> .dropdown {
				position: static;

				&:before {
					content: '';
					display: block;
					position: fixed;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background: rgba(0,0,0,0.65);
					z-index: 9999;
					opacity: 0;
					visibility: hidden;
					transition: all .15s linear;
				}

				&.show .dropdown-menu { transform: translateY(-50%) scale(1) !important; }

				&.show:before {
					opacity: 1;
					visibility: visible;
				}
			}
			&:before, &:after {
				content: '';
				display: block;
				border: 15px solid transparent;
				position: absolute;
				bottom: 100%;
				left: 50%;
				margin-left: -15px;
				margin-bottom: -13px;
			} 
			&:before { border-bottom-color: $color-border; }
			&:after {
				margin-bottom: -16px;
				border-bottom-color: $background-body;
			}
			@media (max-width : 767px) {
				> h3 { font-size: 20px; }
			}
		}
		.dropdown-menu {
			position: fixed !important;
			overflow: hidden;
			max-width: none !important;
			padding: $spacing - 5;
			width: auto;
			left: 8% !important;
			right: 8% !important;
			top: 50% !important;
			transform: translateY(-50%) scale(0.95) !important;
			font-size: 0;
			padding: $spacing ($spacing - 10) ($spacing - 10);
			z-index: 99999;
			background: $background-body;
			max-height: 84%;
			overflow: auto;

			a {
				display: inline-block;
				padding: 10px;
				width: 25%;
				opacity: 1;
				border-radius: $radius;
				cursor: pointer;

				&:hover { opacity: .8; }

				img {
					border: 1px solid rgba(0,0,0,0.1);
					border-radius: $radius;
				}

				@media (max-width : 991px) { width: 50%; }
				@media (max-width : 499px) { width: 100%; }
			}
			h4 {
				margin: 0 (-($spacing - 10)) ($spacing - 10);
				padding: 0 ($spacing - 10) $spacing;
				border-bottom: 1px solid $color-border;
			}
			
			/*@media (max-width : 767px) {
				padding-left: 0;
				padding-right: 0;
				left: 0;
				width: 100% !important;
				margin-left: 0 !important;
				h4 {
					margin: 0 0 ($spacing - 10);
					font-size: 16px;
					line-height: 1.15em;
					&:after {
						content: '(deslize para o lado para ver todas opções)';
						font-size: 75%;
						padding: 0 7px;
						color: $color-opacity;
						font-weight: 400;
						display: inline-block;
					}
				}
				a {
					display: inline-block;
					vertical-align: top;
					float: none;
					width: 50%;
				}
				.blocks {
					overflow: auto;
					-webkit-overflow-scrolling: touch;
					white-space: nowrap;
				}
			}*/
		}
	}