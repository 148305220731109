//!* Custom
.content.form {
	max-width: 848px;
	margin: 0 auto;
}

.subtitle {
	font-weight: bold;
	text-transform: uppercase;
	border-left: 4px solid $redrum-3;
	padding: 4px 0 4px 15px;
	color: $redrum-3;
	font-size: 1.15em;
	margin: ($spacing * 3) 0 ($spacing * 2);
}

//!* Editor
	.ql-header {
		display: block;
		line-height: 1;
		color: currentColor;

		&.ql-active {
			color: #111;
		}
		
		&:before {
			content: 'title';
			font-family: 'Material Icons';
			font-weight: normal;
			font-size: 1em;
			line-height: 1;
			display: block;
		}
	}

	.ql-snow .ql-picker.ql-size .ql-picker-label::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item::before {
	content: 'Normal';
	}

	.ql-snow .ql-picker.ql-size .ql-picker-label[data-value=small]::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=small]::before {
	content: 'Pequeno';
	}

	.ql-snow .ql-picker.ql-size .ql-picker-label[data-value=large]::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=large]::before {
	content: 'Grande';
	}

	.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
	content: 'Salvar';
	font-weight: bold;
	text-transform: uppercase;
	color: $color-body !important;
	margin-left: 12px;
	}

	.ql-snow .ql-tooltip a.ql-action::after {
	content: 'Editar';
	margin-left: 12px;
	font-weight: bold;
	text-transform: uppercase;
	color: $color-body !important;
	}

	.ql-snow .ql-tooltip a.ql-remove::before {
	content: 'Remover';
	font-weight: bold;
	text-transform: uppercase;
	color: $color-body !important;
	}

	.ql-snow .ql-tooltip[data-mode=link]::before {
	content: "Insira um link:";
	}

	.ql-snow .ql-tooltip::before {
		content: 'Acessar:'
	}

	.ql-snow .ql-fill, .ql-snow .ql-stroke.ql-fill {
		fill: $color-body;
	}

	.ql-snow .ql-stroke {
		stroke: $color-body;
	}

	.ql-snow.ql-toolbar button:hover, .ql-snow .ql-toolbar button:hover, .ql-snow.ql-toolbar button:focus, .ql-snow .ql-toolbar button:focus, .ql-snow.ql-toolbar button.ql-active, .ql-snow .ql-toolbar button.ql-active, .ql-snow.ql-toolbar .ql-picker-label:hover, .ql-snow .ql-toolbar .ql-picker-label:hover, .ql-snow.ql-toolbar .ql-picker-label.ql-active, .ql-snow .ql-toolbar .ql-picker-label.ql-active, .ql-snow.ql-toolbar .ql-picker-item:hover, .ql-snow .ql-toolbar .ql-picker-item:hover, .ql-snow.ql-toolbar .ql-picker-item.ql-selected, .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
		color: #111;
		background: rgba(0,0,0,0.1);
		border-radius: $radius;
	}

	.ql-snow.ql-toolbar button:hover .ql-stroke, .ql-snow .ql-toolbar button:hover .ql-stroke, .ql-snow.ql-toolbar button:focus .ql-stroke, .ql-snow .ql-toolbar button:focus .ql-stroke, .ql-snow.ql-toolbar button.ql-active .ql-stroke, .ql-snow .ql-toolbar button.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow.ql-toolbar button:hover .ql-stroke-miter, .ql-snow .ql-toolbar button:hover .ql-stroke-miter, .ql-snow.ql-toolbar button:focus .ql-stroke-miter, .ql-snow .ql-toolbar button:focus .ql-stroke-miter, .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
		stroke: #111;
	}

	.ql-snow.ql-toolbar button:hover .ql-fill, .ql-snow .ql-toolbar button:hover .ql-fill, .ql-snow.ql-toolbar button:focus .ql-fill, .ql-snow .ql-toolbar button:focus .ql-fill, .ql-snow.ql-toolbar button.ql-active .ql-fill, .ql-snow .ql-toolbar button.ql-active .ql-fill, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill, .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill, .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill, .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill, .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
		fill: #111;
	}

	.ql-snow.ql-toolbar button, .ql-snow .ql-toolbar button {
		width: auto;
		height: auto;
		font-size: 20px;
		padding: 5px;

		svg {
			width: 1em;
			height: 1em;
		}
	}

	.ql-snow .ql-formats {
		margin-top: 5px;
		margin-bottom: 5px;
		margin-right: 20px;

		&:last-child {
			margin-right: 0;
		}

		button {
			margin-right: 5px;
		}
	}

	.form-group-editor {
		.ql-toolbar {
			position: static !important;
		}

		.form-editor {
			overflow: hidden;
			border-radius: $radius;
			border: 1px solid $color-border;
			border-bottom-width: 2px;

			> div {
				border-radius: 0;
			}
		}
	}

	.form-editor {
		margin: 0;
		order: 2;

		.label {
			position: static !important;
			margin-bottom: .5em;

			a {
				color: $color-body !important;
				font-size: 90%;
			}
		}

		> div {
			background: white;
			border-radius: $radius;
			box-shadow: $shadow-1;

			&:before {
				content: '';
				display: block;
			}
		}

		.quill-body {
			// max-height: 400px !important;
			// overflow: auto !important;
			box-shadow: none !important;
		}

		.ql-tooltip {
			box-shadow: $shadow-1;
			border: none;
			border-radius: $radius;
		}

		.ql-toolbar {
			position: sticky;
			border-radius: $radius $radius 0 0;
			top: 111px;
			z-index: 2;
			padding: 10px 20px;
			border: none;
			background: white;
			border: none !important;
			border-bottom: 1px solid rgba(0,0,0,0.1) !important;
			font-family: 'Roboto', sans-serif;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-wrap: wrap;

			@media (max-width : 991px) {
				top: 171px;
			}

			@media (max-width : 767px) {
				top: 156px;
			}

			* {
				outline: none;
			}

			.ql-table[value*="-row"]:after {
				background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMjQgMjQiIGZpbGw9IiM0MjQyNDIiPiAgICA8cGF0aCBkPSJNIDQgMyBDIDIuOSAzIDIgMy45IDIgNSBMIDIgMTMgQyAyIDE0LjEgMi45IDE1IDQgMTUgTCA2LjA5Mzc1IDE1IEMgNi4xOTM3NSAxNC4zIDYuNTEyNSAxMy42IDYuODEyNSAxMyBMIDQgMTMgTCA0IDUgTCAyMCA1IEwgMjAgMTMgTCAxNy4xODc1IDEzIEMgMTcuNTg3NSAxMy42IDE3LjgwNjI1IDE0LjMgMTcuOTA2MjUgMTUgTCAyMCAxNSBDIDIxLjEgMTUgMjIgMTQuMSAyMiAxMyBMIDIyIDUgQyAyMiAzLjkgMjEuMSAzIDIwIDMgTCA0IDMgeiBNIDEyIDExIEMgOS4yIDExIDcgMTMuMiA3IDE2IEMgNyAxOC44IDkuMiAyMSAxMiAyMSBDIDE0LjggMjEgMTcgMTguOCAxNyAxNiBDIDE3IDEzLjIgMTQuOCAxMSAxMiAxMSB6IE0gMTEgMTMgTCAxMyAxMyBMIDEzIDE1IEwgMTUgMTUgTCAxNSAxNyBMIDEzIDE3IEwgMTMgMTkgTCAxMSAxOSBMIDExIDE3IEwgOSAxNyBMIDkgMTUgTCAxMSAxNSBMIDExIDEzIHoiLz48L3N2Zz4=);
			}

			.ql-table[value*="newtable"]:after {
				background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMjQgMjQiIGZpbGw9IiM0MjQyNDIiPiAgICA8cGF0aCBkPSJNIDUgMiBDIDQuNDc3Nzc3OCAyIDMuOTM5NTMwNSAyLjE4NTQ2OTUgMy41NjI1IDIuNTYyNSBDIDMuMTg1NDY5NSAyLjkzOTUzMDUgMyAzLjQ3Nzc3NzggMyA0IEwgMyAyMCBDIDMgMjAuNTIyMjIyIDMuMTg1NDY5NSAyMS4wNjA0NjkgMy41NjI1IDIxLjQzNzUgQyAzLjkzOTUzMDUgMjEuODE0NTMxIDQuNDc3Nzc3OCAyMiA1IDIyIEwgMTkgMjIgQyAxOS41MjIyMjIgMjIgMjAuMDYwNDY5IDIxLjgxNDUzMSAyMC40Mzc1IDIxLjQzNzUgQyAyMC44MTQ1MzEgMjEuMDYwNDY5IDIxIDIwLjUyMjIyMiAyMSAyMCBMIDIxIDQgQyAyMSAzLjQ3Nzc3NzggMjAuODE0NTMxIDIuOTM5NTMwNSAyMC40Mzc1IDIuNTYyNSBDIDIwLjA2MDQ2OSAyLjE4NTQ2OTUgMTkuNTIyMjIyIDIgMTkgMiBMIDUgMiB6IE0gNSA0IEwgMTEgNCBMIDExIDggTCA1IDggTCA1IDQgeiBNIDEzIDQgTCAxOSA0IEwgMTkgOCBMIDEzIDggTCAxMyA0IHogTSA1IDEwIEwgMTEgMTAgTCAxMSAxNCBMIDUgMTQgTCA1IDEwIHogTSAxMyAxMCBMIDE5IDEwIEwgMTkgMTQgTCAxMyAxNCBMIDEzIDEwIHogTSA1IDE2IEwgMTEgMTYgTCAxMSAyMCBMIDUgMjAgTCA1IDE2IHogTSAxMyAxNiBMIDE5IDE2IEwgMTkgMjAgTCAxMyAyMCBMIDEzIDE2IHoiIG92ZXJmbG93PSJ2aXNpYmxlIiBlbmFibGUtYmFja2dyb3VuZD0iYWNjdW11bGF0ZSIgZm9udC1mYW1pbHk9IkJpdHN0cmVhbSBWZXJhIFNhbnMiLz48L3N2Zz4=);
			}

			.ql-picker { 
				font-size: 1em;
				height: auto;
				min-height: 30px;
			}

			.ql-picker-options {
				box-shadow: $shadow-1;
				border-radius: $radius;
				border: none;
				padding: 8px;
				margin-top: 5px !important;
			}

			.ql-picker-item {
				border-radius: $radius;
				padding: 5px 10px;
				line-height: 1.15em;
			}

			.ql-picker-label {
				border: none;
				padding: 0 20px 0 10px;

				&:before {
					line-height: 30px;
				}
			}

			.ql-picker {
				color: $color-body !important;

				.ql-stroke {
					stroke: currentColor !important;
				}

				&.ql-expanded .ql-picker-label {
					color: #111;
					background: rgba(0,0,0,0.1);
					border-radius: $radius;
				}
			}

		}

		.ql-container {
			border: none;
		}

		.ql-editor {
			padding: 2vw;
			font-size: 1rem;
			color: currentColor;
			line-height: 1.5em;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			text-rendering: optimizeLegibility;
			font-feature-settings: 'liga';
			overflow: visible;
			
			* {
				color: currentColor !important;
			}

			ul,
			ol,
			p {
				line-height: 1.5em;
				margin: 0 0 1.5rem !important;

				&:last-child {
					margin-bottom: 0 !important;
				}
			}

			ol {
				list-style: upper-roman !important;
			}

			a {
				color: currentColor !important;
				text-decoration: underline !important;

				&:hover {
					color: #161616 !important;
				}
			}

			.ql-size-large {
				font-size: 1.2em;
			}

			.ql-size-small {
				font-size: .85em;
			}

			ul,
			ol {
				@media (max-width : 575px) {
					padding-left: 0;
				}

				li + li {
					margin-top: .5em;
				}
			}

			h1, h2, h3 {
				line-height: 1.15em;
				margin: 1.15em 0 !important;
				font-weight: bold;

				&:first-child {
					margin-top: 0 !important;
				}
				
				&:last-child {
					margin-bottom: 0 !important;
				}
			}

			h1 {
				font-size: 30px;

				@media (max-width : 1280px) { font-size: 28px; }
				@media (max-width : 575px) { font-size: 26px; }
			}

			h2 {
				font-size: 24px;

				@media (max-width : 1280px) { font-size: 23px; }
				@media (max-width : 575px) { font-size: 21px; }
			}

			h3 {
				font-size: 21px;

				@media (max-width : 1280px) { font-size: 20px; }
				@media (max-width : 575px) { font-size: 19px; }
			}

			a {
				color: $redrum-3 !important;
			}

			strong {
				font-weight: bold;
			}

			table {
				td {
					vertical-align: top;
					border-color: #bababa;
					padding: 8px;
					width: 50% !important;
				}

				ul,
				ol {
					padding-left: 0 !important;
				}
			}

			&.ql-blank:before {
				color: $color-opacity;
				left: 2vw;
			}
		}
	}